import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
    state: {
        menuItems: null,
        options: null,
        pageData: [],
    },
    getters: {
        options (state) {
            return state.options
        },

        pageData (state) {
            return state.pageData
        },        
    },
    mutations: {
        SET_OPTIONS (state, value) {
            state.options = value
        },

        SET_PAGEDATA (state, value) {
            state.pageData.push(value);
        }
    },
    actions: {
        getOptions({ commit }) {
            return axios.get(process.env.VUE_APP_API_BASE_URL + 'pages?slug=options').then((response) => {
                commit('SET_OPTIONS', response.data[0].acf)
            }).catch(() => {
                commit('SET_OPTIONS', null)
            })
        },

        getPageData({ commit, getters }, params) {
            if (getters.pageData.find((obj) => obj.slug === params.slug)) {
                // console.log('Getting page data from the store!');
                return getters.pageData;
            } else {
                // console.log('Getting page data via api!');
                return axios.get(process.env.VUE_APP_API_BASE_URL + params.postType + '?slug=' + params.slug).then((response) => {
                    if (response.data.length === 0) {
                        return false;
                    }
                    
                    let relatedPost = false;
                    if (typeof response.data[0].next_post !== "undefined") {
                        relatedPost = response.data[0].next_post;
                    }

                    const data = {
                        slug: params.slug,
                        acf: response.data[0].acf,
                        title: response.data[0].yoast_head_json.og_title ?? '',
                        desc: response.data[0].yoast_head_json.og_description ?? '',
                        tags: response.data[0].project_tags ?? false,
                        relatedPost: relatedPost,
                        date: response.data[0].date_gmt ?? false,
                    };

                    commit('SET_PAGEDATA', data)
                }).catch((e) => {
                    console.log(e)
                })
            }
        }
    },
    modules: {
    }
})
