import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import ScrollTrigger from "gsap/ScrollTrigger"
import { routes } from './routes.js'

async function getPageData(slug, postType) {
    await store.dispatch('getPageData', {slug, postType});
    return store.getters.pageData;
}

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    let slug = to.path;
    if (slug === '/') slug = 'home';

    let postType = 'pages';

    // console.log(slug);

    if (slug.includes('/projects')) {
        slug = slug.replace(/\/$/, '').split('/').pop();
        postType = 'projects';
    } else if (slug.includes('/insights') && (slug !== '/insights' && slug !== '/insights/')) {
        slug = slug.replace(/\/$/, '').split('/').pop();
        postType = 'posts';
    } else {
        slug = slug.replace(/\//g, "");
    }
        
    await getPageData(slug, postType).then(response => {
        if (response.length === 0) {
            document.title = '404 Page Not Found - Bad Bears Studio';
            document.querySelector('meta[name="description"]').setAttribute("content", 'Opps, page not found, Please bear with us while we get you back home.');
            slug = '404';
        } else {
            const pageData = response.find((obj) => obj.slug === slug);

            if (pageData) {
                if (pageData.title) document.title = pageData.title;
                if (pageData.desc) document.querySelector('meta[name="description"]').setAttribute("content", pageData.desc ?? '');
            }
        }
    });

    //Body Class
    const el = document.body;
    const prefix = "page-";
    const classes = el.className.split(" ").filter(c => !c.startsWith(prefix));
    el.className = classes.join(" ").trim();
    if (postType === 'pages') el.classList.add('page-' + slug);
    if (postType === 'projects') el.classList.add('page-work-single');
    if (postType === 'posts') el.classList.add('page-blog-single');

    window.scrollTo({ top: 0 });
    el.removeAttribute("style");
    ScrollTrigger.getAll().forEach(t => t.kill());
    
    next();
});

export default router