import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/app.scss'
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(VueGtag, {
    appName: 'Bad Bears (MAIN)',
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-D49LL4TB2E" },
    enabled: false,
}, router);

// app.provide('gtag', app.config.globalProperties.$gtag);

app.config.globalProperties.$filters = {
    filterAdminURL(string) {
        string = string.replace(/^.*\/\/[^/]+/, '');
        return string.replace('/admin', '');
    },

    getSlug(url) {
        return url.replace(/\/$/, '').split('/').pop();
    },
}

const getOptions = store.dispatch('getOptions').catch((error) => {
    console.log(error);
});

Promise.all([getOptions]).then(() => {
    app.use(store).use(router).mount('#app');
});
