<template>
    <cursor-component />
    <header-component></header-component>
    <router-view :key="$route.fullPath" />
    <footer-component></footer-component>
    <vue-cookie-accept-decline
        @clicked-accept="cookiesAccepted"
        @status="cookieStatus"
        elementId="cookie-banner"
        position="bottom-left"
        ref="cookie-banner"
        transitionName="slideFromBottom"
        type="floating"
        >
        <template #message>
            This website uses cookies to ensure you get the best experience on our website.
            <router-link to="/privacy">Privacy Policy</router-link>
        </template>

        <template #declineContent>Close</template>
        <template #acceptContent>Accept</template>
    </vue-cookie-accept-decline>
</template>

<script>
    import { defineComponent } from 'vue'
    import CursorComponent from '@/components/global/CursorComponent.vue';
    import HeaderComponent from '@/components/header/HeaderComponent.vue';
    import FooterComponent from '@/components/footer/FooterComponent.vue';
    import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

    export default defineComponent({
        components: {
            CursorComponent,
            HeaderComponent,
            FooterComponent,
            VueCookieAcceptDecline
        },

        data() {
            return {
            }
        },

        methods: {
            cookiesAccepted() {
                this.$gtag.optIn();
            },
            cookieStatus(status) {
                // console.log(status);
                if (status === 'accept') this.$gtag.optIn();
            }
        }
    })
</script>

<style scoped lang="scss">
    @import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

    .cookie {
        a {
            text-decoration: underline;
        }

        &.cookie__floating.cookie__floating--bottom-left {
            background: theme('colors.white');
            box-shadow: none;
            border: 0 none;
            font-size: 1rem;
            font-family: 'Space Mono', sans-serif;
            line-height: normal;
            border-radius: 0;
            color: theme('colors.black');
        }

        :deep(.cookie__floating__content) {
            max-height: none;
            font-size: 0.875rem;
            margin-bottom: 0;
        }

        :deep(.cookie__floating__buttons) {
            padding: 15px 20px;
        }

        :deep(.cookie__floating__buttons__button) {
            display: inline-block;
            height: 32px;
            padding: 0 1.75rem;
            background-color: transparent;
            line-height: 32px;
            border: 1px solid theme('colors.pink');
            color: theme('colors.pink');
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            transition: 0.3s;
            border-radius: 3px;

            &:hover,
            &:active,
            &:focus {
                background-color: theme('colors.pink');
                color: theme('colors.black');
                opacity: 1;
            }

            &:disabled,
            &.btn--disabled {
                border: 2px solid theme('colors.gray.400');
                background-color: theme('colors.gray.700');
                color: theme('colors.white');
                cursor: not-allowed;

                &:hover,
                &:active,
                &:focus {
                    background-color: theme('colors.gray.400');
                }
            }
        }
        
        :deep(.cookie__floating__buttons__button--accept) {
            background-color: theme('colors.pink');
            color: theme('colors.white');
            margin-left: 10px;
        }
    }
</style>
