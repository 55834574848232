<template>
<div class="cursor">
    <div id="cursor-big" class="cursor-ball cursor-ball--big"></div>
    <div id="cursor-small" class="cursor-ball cursor-ball--small"></div>
</div>
</template>

<script>
    import { defineComponent } from 'vue';
    import gsap from "gsap";

    export default defineComponent({
        name: 'CursorComponent',
    
        mounted() {
            const cursorBig = document.getElementById('cursor-big');
            const cursorSmall = document.getElementById('cursor-small');

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mousedown", onMouseHover);
            document.addEventListener("mouseup", onMouseHoverOut);
            
            document.addEventListener("mouseenter", () => {
                cursorBig.style.opacity = '1';
                cursorSmall.style.opacity = '1';
            });

            document.addEventListener("mouseleave", () => {
                cursorBig.style.opacity = '0';
                cursorSmall.style.opacity = '0';
            });

            function onMouseMove(e) {
                cursorSmall.style.opacity = '1';

                gsap.to(cursorBig, {
                    x: e.clientX - 15,
                    y: e.clientY - 15,
                    duration: 0.1,
                });
                
                gsap.to(cursorSmall, {
                    x: e.clientX - 12,
                    y: e.clientY - 12,
                    duration: 0.1,
                });
            }

            function onMouseHover() {
                cursorBig.style.opacity = '1';

                gsap.to(cursorBig, {
                    scale: 3,
                    duration: 0.3,
                });
            }

            function onMouseHoverOut() {
                cursorBig.style.opacity = '0';

                gsap.to(cursorBig, {
                    scale: 1,
                    duration: 0.3,
                });
            }
        }
    });
</script>

<style lang="scss">
    @mixin anyPointer {
        @media (any-pointer: fine) {
            @content;
        }
    }

    * {
        @include anyPointer {
            cursor: none !important;
        }
    }

    .cursor {
        display: none;

        @include anyPointer {
            display: block;
            pointer-events: none;
        }
    }

    .cursor-ball {
        position: fixed;
        top: 0;
        left: 0;
        mix-blend-mode: difference;
        z-index: 99999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s ease;
    }

    .cursor-ball--big {
        content: "";
        width: 30px;
        height: 30px;
        background: theme('colors.pink');
        border-radius: 50%;
    }

    .cursor-ball--small {
        content: "";
        width: 24px;
        height: 24px;
        background: theme('colors.black');
        border: 3px solid theme('colors.pink');
        border-radius: 50%;
    }
</style>