<template>
    <div id="socials" class="socials">
        <div class="social__icons">
            <a class="social__link" v-if="options.facebook" :href="options.facebook" target="_blank">
                <inline-svg class="social__icon" :src="require('@/assets/images/facebook.svg')" />
            </a>

            <a class="social__link" v-if="options.instagram" :href="options.instagram" target="_blank">
                <inline-svg class="social__icon" :src="require('@/assets/images/instagram.svg')" />
            </a>
            
            <a class="social__link" v-if="options.linkedin" :href="options.linkedin" target="_blank">
                <inline-svg class="social__icon" :src="require('@/assets/images/linkedin.svg')" />
            </a>
        </div>
    </div>

    <div id="timezones" class="timezones">
        <div v-for="(timezone, index) in options.footer.timezones.split(',')" :key="index">
        {{ timezone.split(' ')[0] }}
        {{ new Date().toLocaleTimeString('en-GB', { timeZone: timezone.split(' ')[1], timeStyle: "short" }) }}
        </div>
    </div>

    <footer id="footer" class="footer bg-black text-white" v-if="showComponent">
        <div class="footer__wrapper wrapper">
            <div class="md:flex justify-between items-start">
                <div class="footer__left md:flex">
                    <a href="/">
                        <img v-if="options.footer.logo.url" class="footer__logo" :src="options.footer.logo.url" :alt="options.footer.logo.alt" />
                    </a>
                    
                    <div class="footer__email" v-if="options.footer.email">
                        <p class="mb-0 font-bold">Email</p>
                        <p class="mb-0"><a :href="'mailto:' + options.footer.email">
                            {{ options.footer.email }}
                        </a></p>

                        <div class="social__icons social__icons--footer">
                            <a class="social__link" v-if="options.facebook" :href="options.facebook" target="_blank">
                                <img class="social__icon" src="@/assets/images/facebook.svg" alt="Follow us on Facebook" />
                            </a>

                            <a class="social__link" v-if="options.instagram" :href="options.instagram" target="_blank">
                                <img class="social__icon" src="@/assets/images/instagram.svg" alt="Follow us on Instagram" />
                            </a>
                            
                            <a class="social__link" v-if="options.linkedin" :href="options.linkedin" target="_blank">
                                <img class="social__icon" src="@/assets/images/linkedin.svg" alt="Follow us on LinkedIn" />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="footer__right">
                    <div class="footer__copyright" v-html="options.footer.copyright"></div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import { defineComponent } from 'vue';
    import { mapGetters } from 'vuex';
    import InlineSvg from 'vue-inline-svg';

    export default defineComponent({
        name: 'FooterComponent',
        props: {},
        components: {
            InlineSvg
        },

        data() {
            return {
                showComponent: false,
                isMobile: false,
            }
        },

        computed: {
            ...mapGetters({
                options: 'options',
            }),
        },

        mounted() {
            this.isMobileCheck();

            setTimeout(() => {
                this.showComponent = true;
            }, 1000)

            window.addEventListener('scroll', function() {
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - (document.getElementById("footer").offsetHeight - (this.isMobile ? 50 : 0))) {
                    document.getElementById("socials").classList.add('socials--bottom');
                    document.getElementById("timezones").classList.add('timezones--bottom');
                } else {
                    document.getElementById("socials").classList.remove('socials--bottom');
                    document.getElementById("timezones").classList.remove('timezones--bottom');
                }
            });
        },

        methods: {
            isMobileCheck() {
                this.isMobile = screen.width <= 767 ?  true : false;
            }, 
        },

    });
</script>

<style lang="scss">
    .socials {
        display: none;
        position: fixed;
        left: 0.625rem;
        bottom: 1.25rem;
        mix-blend-mode: difference;
        transition: 0.3s;
        justify-content: space-between;
        align-items: flex-end;

        @screen md {
            left: 1.25rem;
            bottom: 3.125rem;
            display: flex;  
        }

        @screen 2xl {
            left: 3.125rem;
            bottom: 3.125rem;        
        }
    }

    .socials--bottom {
        opacity: 0;
        z-index: -10;
    }

    .timezones {
        display: none;
        position: fixed;
        right: 0.625rem;
        bottom: 1.25rem;
        mix-blend-mode: difference;
        transition: 0.3s;
        justify-content: space-between;
        align-items: flex-end;

        @screen md {
            right: 1.25rem;
            bottom: 3.125rem;
            display: block;     
        }

        @screen 2xl {
            right: 3.125rem;
            bottom: 3.125rem;        
        }
    }

    .timezones--bottom {
        opacity: 0;
        z-index: -10;
    }

    .social__link {
        display: block;
        margin: 0 0 0.3125rem;
        text-align: center;

        @screen md {
            margin: 0 0 0.625rem;
        }
    }

    .social__icon {
        height: 18px;
        display: inline-block;

        @screen md {
            height: 24px;
        }
    }

    .social__icons--footer {
        display: flex;
        margin-top: 0.3125rem;

        .social__link {
            margin: 0 0.625rem 0 0;
        }

        .social__icon {
            height: 18px;
        }
    }

    .page-work,
    .page-contact,
    .page-about,
    .page-work-single,
    .page-blog-single {
        .social__icons {
            .social__icon {
                path {
                    fill: theme('colors.black');
                }
            }
        }
    }

    .page-services,
    .page-404 {
        .timezones {
            mix-blend-mode: normal;
            @apply text-white;
        }
    }

    .footer {
        padding-top: 3.125rem;
    }
    
    .footer__wrapper {
        padding: 1.25rem;
        width: 1340px;
        border-top: 1px solid theme('colors.white');

        @screen lg {
            padding: 3.125rem;
        }
    }

    .footer__copyright {
        margin-top: 1.25rem;

        @screen md {
            margin-top: 0;
        }
    }

    .footer p {
        font-size: 0.875rem;
    }

    .footer__logo {
        margin: 0 0 0.625rem;

        @screen md {
            margin-right: 3.125rem;
        }

        @screen xl {
            margin-right: 6.25rem;
        }
    }
</style>
