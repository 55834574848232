export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/work',
        name: 'work',
        component: () => import('../views/WorkView.vue')
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('../views/ServicesView.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/ContactView.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/PrivacyPolicyView.vue')
    },
    {
        path: '/insights',
        name: 'blog',
        component: () => import('../views/BlogView.vue')
    },
    {
        path: '/projects/:slug',
        name: 'workSingle',
        component: () => import('../views/WorkSingleView.vue'),
    },
    {
        path: '/insights/:slug',
        name: 'blogSingle',
        component: () => import('../views/BlogSingleView.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/PageNotFoundView.vue'),
    }
];