<template>
    <header class="header" ref="header">
        <a href="/" class="block"><img class="header__logo" :src="options.header.logo.url" :alt="options.header.logo.url"></a>

        <button class="menu-btn" @click.prevent="toggleMenu()" ref="menuBtn">
            <span class="menu-btn__text" ref="menuText">MENU</span>
            <inline-svg class="menu-btn__stroke-1" :src="require('@/assets/images/paint-stroke.svg')" />
            <inline-svg class="menu-btn__stroke-2" :src="require('@/assets/images/paint-stroke.svg')" />
        </button>

        <div class="main-menu" ref="mainMenu">
            <div class="main-menu__inner">
                <div class="wrapper px-5 md:px-12">
                    <div class="menu__content">
                        <span class="menu_title">Menu</span>

                        <ul class="menu__items">
                            <li class="menu__item" v-for="item in menuItems" :key="item">
                                <template v-if="item.external">
                                    <a class="menu__link" :href="item.slug" target="_blank">
                                        {{ item.name }}
                                    </a>
                                </template>
                                <template v-else>
                                    <router-link class="menu__link" :to="'/' + item.slug">
                                        {{ item.name }}
                                    </router-link>
                                </template>
                            </li>
                        </ul>

                        <img class="menu__mascot" src="@/assets/images/menu-mascot.png" alt="Bad Bears Menu Mascot" />
                    </div>
                </div>
            </div>
            <svg viewBox="0 0 1930 289" fill="none" xmlns="http://www.w3.org/2000/svg" class="main-menu__wave">
                <path d="M0 0.525024H1929.8C1929.8 0.525024 1841.09 179.9 1580.53 173.986C1416.43 175.392 1217.93 4.74397 1032.35 153.142C823.44 299.681 600.114 307.032 470.877 269.877C136.29 173.685 0 0.525024 0 0.525024Z" fill="white"/>
            </svg>
        </div>
    </header>
</template>

<script>
    import { defineComponent } from 'vue';
    import { mapGetters } from 'vuex';
    import InlineSvg from 'vue-inline-svg';
    import gsap from 'gsap';

    export default defineComponent({
        name: 'HeaderComponent',
        props: {},
        components: {
            InlineSvg,
        },

        data() {
            return {
                menuItems: [
                    {
                        name: 'Home',
                        slug: '',
                        external: false,
                    },
                    {
                        name: 'Work',
                        slug: 'work',
                        external: false,
                    },
                    {
                        name: 'Services',
                        slug: 'services',
                        external: false,
                    },
                    {
                        name: 'About us',
                        slug: 'about',
                        external: false,
                    },
                    {
                        name: 'Insights',
                        slug: 'insights',
                        external: false,
                    },
                    {
                        name: 'Contact us',
                        slug: 'contact',
                        external: false,
                    }
                ]
            }
        },

        watch: {
            '$route' () {
                if (this.$refs.menuBtn.classList.contains('menu-btn--open')) {
                    this.toggleMenu();
                }
            }
        },

        computed: {
            ...mapGetters({
                options: 'options',
            }),
        },

        created() {
            gsap.from(this.$refs.header, {
                opacity: 0, 
                y: -60, 
                duration: 1
            });
        },

        methods: {
            toggleMenu() {
                const menuBtn = this.$refs.menuBtn;
                const mainMenu = this.$refs.mainMenu;
                const menuText = this.$refs.menuText;

				if (menuBtn.classList.contains('menu-btn--open')) {
					mainMenu.classList.remove('main-menu--open');
					menuBtn.classList.remove('menu-btn--open');
					// document.body.classList.remove('overflow-hidden');
                    menuText.textContent = "MENU";
				} else {
					mainMenu.classList.add('main-menu--open');
					menuBtn.classList.add('menu-btn--open');
					// document.body.classList.add('overflow-hidden');
                    menuText.textContent = "CLOSE";
				}
            },
        },
    });
</script>

<style scoped lang="scss">
    $menu_anim_duration: 1s;

    .header {
        position: fixed;
        top: 0;
        width: 100%;;
        z-index: 9998;
        @apply flex justify-between;
        padding: 1.25rem;

        @screen lg {
            padding: 3.125rem 3.125rem 0;
        }
    }

    .header__logo {
        max-width: 50px;
        
        @screen md {
            max-width: 100%;
        }

    }

    .menu-btn {
        position: relative;
        text-align: center;
        width: 111px;
        transition: 0.3s;
        z-index: 10;

        &:hover {
            transform: scale(1.1);
        }
    }

    .menu-btn--open {
        .menu-btn__stroke-1,
        .menu-btn__stroke-2 {
            fill: theme('colors.white');
        }

        .menu-btn__stroke-1 {
            transform: rotate(30deg);
        }

        .menu-btn__stroke-2 {
            transform: rotate(-30deg);
        }
    }

    .menu-btn__text {
        position: relative;
        z-index: 5;
        color: theme('colors.black');
        margin-left: 1.25rem;
    }

    .menu-btn__stroke-1,
    .menu-btn__stroke-2 {
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        margin: auto;
        fill: theme('colors.pink');
        z-index: 4;
        transition: 0.7s;
    }

    .main-menu {
        position: absolute;
        overflow: hidden;
        height: 0;
        top: 0;
        left: 0;
        width: 100%;
        transition: $menu_anim_duration;
        z-index: 3;
        font-family: 'Poppins', sans-serif;
        font-size: 3rem;
        font-weight: 800;
        text-transform: uppercase;
        color: theme('colors.black');

        @screen md {
            font-size: 6rem;
        }
    }

    .main-menu--open {
        height: 100vh;

        .main-menu__inner {
            height: 100%;
        }

        .main-menu__wave {
            transform: scaleY(1); 
            path {
                // d: path("M5.00003 0.525024H1929.8C1929.8 0.525024 2057 478 1777 478C1612.89 479.406 1545 406 1213 446C1004.09 592.539 134.237 399.155 5 362C-329.587 265.808 5.00003 0.525024 5.00003 0.525024Z"); 
            }
        }

        .menu__content {
            // opacity: 1;
        }
    }

    .main-menu__inner {
        height: 0;
        background: theme('colors.pink');
        z-index: 3;
        position: relative;
        transition: $menu_anim_duration;
    }

    .main-menu__wave {
        color: red;
        transform: scaleY(0);
        transform-origin: top;
        // position: absolute;
        // z-index: 2;
        transition: $menu_anim_duration;

        path {
            transition: $menu_anim_duration;
            // d: path("M0 0.525024H1929.8C1929.8 0.525024 1841.09 179.9 1580.53 173.986C1416.43 175.392 1217.93 4.74397 1032.35 153.142C823.44 299.681 600.114 307.032 470.877 269.877C136.29 173.685 0 0.525024 0 0.525024Z");
        }
    }

    .menu__content {
        @apply pt-10 md:pt-0 md:flex items-center justify-center;
        height: 100vh;
        min-height: 500px;
    }

    .menu__items {
        @apply px-5 md:px-12;
    }

    .menu__item {
        display: block;
        @apply mt-2.5 md:mt-0;

        &:first-child {
            @apply mt-5 md:mt-0;
        }
    }

    .menu_title,
    .menu__link {
        letter-spacing: -3.1px;
        display: block;
        line-height: 1;

        @screen md {
            letter-spacing: -6.2px;
        }
    }

    .menu__link {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -5%;
            width: 110%;
            height: 100%;
            background-image: url(@/assets/images/menu-paint.png);
            background-size: auto 100%;
            background-position: center left;
            background-repeat: no-repeat;
            opacity: 0;
            transition: 0.3s;
            z-index: -1;
        }

        &:hover,
        &:focus {
            opacity: 1;

            &::before {
                opacity: 1;
            }
        }
    }

    .menu__mascot {
        max-width: 150px;
        margin-top: 2.5rem;

        @screen md {
            max-width: 217px;
            margin-top: 0;
        }
        
    }
</style>
